// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

export const helpData = {
  publications: {
    "Backup and Recovery":
      "https://www.hpe.com/support/GLBackupAndRecoveryArticles",
    "Block Storage": "https://www.hpe.com/support/GLBlockStorageArticles",
    "Data Ops Manager": "https://www.hpe.com/support/DataOpsManagerArticles",
    "Disaster Recovery":
      "https://www.hpe.com/support/GLDisasterRecoveryArticles",
    "File Storage": "https://www.hpe.com/support/GLFileServicesArticles",
    "Private Cloud Business Edition":
      "https://www.hpe.com/support/GLPCBEArticles",
    "Storage Fabric Management": "https://www.hpe.com/support/GLSFMArticles",
    "Setup Service": "https://www.hpe.com/support/SetupServiceArticles",
  },
  topics: {
    default: "default-article-tt-dscc",

    "/audit-log": "ps-auditlog-tt-dscc",

    "/backup-and-recovery": "bar-overview-tt-dscc",
    "/backup-and-recovery/activities": "bar-activities-tt-dscc",
    "/backup-and-recovery/analytics/activities": "bar-activities-tt-dscc",
    "/backup-and-recovery/analytics/reports/*":
      "bar-reports-protectionjobs-tt-dscc",
    "/backup-and-recovery/aws/": "bar-aws-accounts-tt-dscc",
    "/backup-and-recovery/aws/accounts/*": "bar-aws-accounts-tt-dscc",
    "/backup-and-recovery/aws/ebs/*": "bar-aws-ebs-tt-dscc",
    "/backup-and-recovery/aws/ec2/*": "bar-aws-ec2-tt-dscc",
    "/backup-and-recovery/aws/eks/*": "bar-aws-eks-clusters-tt-dscc",
    "/backup-and-recovery/aws/protection-groups/*":
      "bar-aws-protectiongroups-tt-dscc",
    "/backup-and-recovery/aws/rds/*": "bar-aws-rds-tt-dscc",
    "/backup-and-recovery/cloud/aws": "bar-aws-accounts-tt-dscc",
    "/backup-and-recovery/cloud/aws/accounts/*": "bar-aws-accounts-tt-dscc",
    "/backup-and-recovery/cloud/aws/ebs/*": "bar-aws-ebs-tt-dscc",
    "/backup-and-recovery/cloud/aws/ec2/*": "bar-aws-ec2-tt-dscc",
    "/backup-and-recovery/cloud/aws/eks/*": "bar-aws-eks-clusters-tt-dscc",
    "/backup-and-recovery/cloud/aws/protection-groups/*":
      "bar-aws-protectiongroups-tt-dscc",
    "/backup-and-recovery/cloud/aws/rds/*": "bar-aws-rds-tt-dscc",
    "/backup-and-recovery/dashboard": "bar-overview-tt-dscc",
    "/backup-and-recovery/mssql": "bar-mssql-applicationhosts-tt-dscc",
    "/backup-and-recovery/mssql/application-hosts/*":
      "bar-mssql-applicationhosts-tt-dscc",
    "/backup-and-recovery/mssql/databases/*": "bar-mssql-databases-tt-dscc",
    "/backup-and-recovery/mssql/instances/*": "bar-mssql-instances-tt-dscc",
    "/backup-and-recovery/mssql/protection-groups/*":
      "bar-mssql-protectiongroups-tt-dscc",
    "/backup-and-recovery/on-prem": "bar-onprem-do-tt-dscc",
    "/backup-and-recovery/on-prem/mssql": "bar-mssql-applicationhosts-tt-dscc",
    "/backup-and-recovery/on-prem/mssql/databases":
      "bar-mssql-databases-tt-dscc",
    "/backup-and-recovery/on-prem/mssql/instances":
      "bar-mssql-instances-tt-dscc",
    "/backup-and-recovery/on-prem/mssql/protection-groups":
      "bar-mssql-protectiongroups-tt-dscc",
    "/backup-and-recovery/on-prem/vmware/datastores/*":
      "bar-vmware-datastores-tt-dscc",
    "/backup-and-recovery/on-prem/vmware/protection-groups/*":
      "bar-vmware-protectiongroups-tt-dscc",
    "/backup-and-recovery/on-prem/vmware/vcenter-servers/*":
      "bar-vmware-vcenterservers-tt-dscc",
    "/backup-and-recovery/on-prem/vmware/virtual-machines":
      "bar-vmware-virtualmachines-tt-dscc",
    "/backup-and-recovery/on-prem/volume":
      "bar-volume-protectiongroups-tt-dscc",
    "/backup-and-recovery/on-prem/volume/protection-groups/*":
      "bar-mssql-protectiongroups-tt-dscc",
    "/backup-and-recovery/onprem-setup": "bar-onprem-do-tt-dscc",
    "/backup-and-recovery/onprem-setup/data-orchestrators/*":
      "bar-onprem-do-tt-dscc",
    "/backup-and-recovery/onprem-setup/protection-store-gateways/*":
      "bar-onprem-psg-tt-dscc",
    "/backup-and-recovery/onprem-setup/protection-stores/*":
      "bar-onprem-ps-tt-dscc",
    "/backup-and-recovery/onprem-setup/storeonces/*":
      "bar-onprem-storeonce-tt-dscc",
    "/backup-and-recovery/protection-policies/*":
      "bar-protectionpolicies-tt-dscc",
    "/backup-and-recovery/protection-workloads/application-hosts":
      "bar-mssql-applicationhosts-tt-dscc",
    "/backup-and-recovery/protection-workloads/aws/accounts":
      "bar-aws-accounts-tt-dscc",
    "/backup-and-recovery/protection-workloads/aws/ebs": "bar-aws-ebs-tt-dscc",
    "/backup-and-recovery/protection-workloads/aws/ec2": "bar-aws-ec2-tt-dscc",
    "/backup-and-recovery/protection-workloads/aws/protection-groups":
      "bar-aws-protectiongroups-tt-dscc",
    "/backup-and-recovery/protection-workloads/mssql/databases":
      "bar-mssql-databases-tt-dscc",
    "/backup-and-recovery/protection-workloads/mssql/instances":
      "bar-mssql-instances-tt-dscc",
    "/backup-and-recovery/protection-workloads/mssql/protection-groups":
      "bar-mssql-protectiongroups-tt-dscc",
    "/backup-and-recovery/protection-workloads/vmware/datastores":
      "bar-vmware-datastores-tt-dscc",
    "/backup-and-recovery/protection-workloads/vmware/protection-groups":
      "bar-vmware-protectiongroups-tt-dscc",
    "/backup-and-recovery/protection-workloads/vmware/vcenter-servers":
      "bar-vmware-vcenterservers-tt-dscc",
    "/backup-and-recovery/protection-workloads/vmware/virtual-machines":
      "bar-vmware-virtualmachines-tt-dscc",
    "/backup-and-recovery/protection-workloads/volume/protection-groups":
      "bar-volume-protectiongroups-tt-dscc",
    "/backup-and-recovery/protection/policies/*":
      "bar-protectionpolicies-tt-dscc",
    "/backup-and-recovery/protection/policies-v2/*":
      "bar-protectionpolicies-tt-dscc",
    "/backup-and-recovery/protection/stores/*": "bar-onprem-ps-tt-dscc",
    "/backup-and-recovery/reports/*": "bar-reports-protectionjobs-tt-dscc",
    "/backup-and-recovery/setup/application-hosts/*":
      "bar-mssql-applicationhosts-tt-dscc",
    "/backup-and-recovery/setup/data-orchestrators/*": "bar-onprem-do-tt-dscc",
    "/backup-and-recovery/setup/protection-store-gateways/*":
      "bar-onprem-psg-tt-dscc",
    "/backup-and-recovery/setup/storeonces/*": "bar-onprem-storeonce-tt-dscc",
    "/backup-and-recovery/tasks": "ps-tasks-tt-dscc",
    "/backup-and-recovery/vmware/datastores/*": "bar-vmware-datastores-tt-dscc",
    "/backup-and-recovery/vmware/protection-groups/*":
      "bar-vmware-protectiongroups-tt-dscc",
    "/backup-and-recovery/vmware/vcenter-servers/*":
      "bar-vmware-vcenterservers-tt-dscc",
    "/backup-and-recovery/vmware/virtual-machines/*":
      "bar-vmware-virtualmachines-tt-dscc",
    "/backup-and-recovery/volume/*": "bar-volume-protectiongroups-tt-dscc",

    "/block": "blockstorage-overview-tt-dscc",
    "/block/dashboard": "blockstorage-overview-tt-dscc",
    "/block/data-access": "blockstorage-overview-tt-dscc",
    "/block/data-access/host-initiator-groups":
      "blockstorage-data-access-tt-dscc",
    "/block/data-access/host-initiators": "blockstorage-data-access-tt-dscc",
    "/block/volumes": "blockstorage-volumetoplevel-tt-dscc",
    "/block/volumes/device-type1": "blockstorage-volumes-tt-dscc",
    "/block/volumes/device-type1/volume-sets/*":
      "blockstorage-volumesets-tt-dscc",
    "/block/volumes/device-type2": "blockstorage-volumes-tt-dscc",
    "/block/volumes/device-type2/volume-sets/*":
      "blockstorage-volumesets-tt-dscc",
    "/block/volumes/device-type3": "blockstorage-volumes-tt-dscc",
    "/block/volumes/device-type4": "blockstorage-volumes-tt-dscc",
    "/block/volumes/device-type4/volume-sets/*":
      "blockstorage-volumesets-tt-dscc",

    "/data-ops-manager": "dom-dashboard-tt-dscc",
    "/data-ops-manager/cloud/aws-accounts": "dom-cloud-aws-account-tt-dscc",
    "/data-ops-manager/cloud/sds-systems": "dom-cloud-sds-tt-dscc",
    "/data-ops-manager/dashboard": "dom-dashboard-tt-dscc",
    "/data-ops-manager/data-access": "dom-hostinitgrp-tt-dscc",
    "/data-ops-manager/data-access/host-initiator-groups":
      "dom-hostinitgrp-tt-dscc",
    "/data-ops-manager/data-access/host-initiators": "dom-hostinit-tt-dscc",
    "/data-ops-manager/data-access/host-initiators/:deviceID":
      "dom-hostinit-tt-dscc",
    "/data-ops-manager/file-shares": "dom-file-devices-tt-dscc",
    "/data-ops-manager/object/access-policies":
      "dom-dt7-system-details-access-policies-tt-dscc",
    "/data-ops-manager/object/buckets": "dom-dt7-object-storage-tt-dscc",
    "/data-ops-manager/object/buckets/:bucketID":
      "dom-dt7-bucket-details-tt-dscc",
    "/data-ops-manager/object/groups": "dom-dt7-system-details-groups-tt-dscc",
    "/data-ops-manager/object/users": "dom-dt7-system-details-users-tt-dscc",
    "/data-ops-manager/storage-systems": "dom-systems-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=CAPACITY":
      "dom-capacity-block-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=HARDWARE":
      "dom-dt1-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=ISSUES":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=NETWORKING":
      "dom-networking-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=PERFORMANCE":
      "dom-dt1-performance-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=PROTECTION":
      "dom-dt-1-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=SETTINGS":
      "dom-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=SOFTWARE":
      "dom-dt1-software-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID?activeTab=SUMMARY":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/enclosures/:enclosureId":
      "dom-dt1-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/networking/dns-servers":
      "dom-dt-1-networking-dns-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/networking/interfaces":
      "dom-dt-1-networking-interfaces-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/networking/proxy-settings":
      "dom-dt-1-networking-proxy-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/nodes/:nodeId":
      "dom-dt1-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/ports":
      "dom-dt1-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/replication":
      "dom-dt-1-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/settings/alerts-monitoring":
      "dom-dt-1-settings-alerts-monitoring-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/settings/date-time":
      "dom-dt-1-settings-date-time-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/settings/management-services":
      "dom-dt-1-settings-mgmt-services-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/settings/security":
      "dom-dt-1-settings-security-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/:deviceID/settings/system-settings":
      "dom-dt-1-system-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type1/volumes":
      "dom-volumes-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=CAPACITY":
      "dom-capacity-block-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=HARDWARE":
      "dom-dt2-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=ISSUES":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=NETWORKING":
      "dom-networking-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=PERFORMANCE":
      "dom-dt2-performance-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=PROTECTION":
      "dom-dt-2-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=SETTINGS":
      "dom-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID?activeTab=SOFTWARE":
      "dom-dt2-software-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/add-array":
      "dom-dt2-add-array-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/hardware":
      "dom-dt2-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/networking/dns-servers":
      "dom-dt-2-networking-dns-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/networking/interfaces":
      "dom-dt-2-networking-interfaces-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/networking/proxy-settings":
      "dom-dt-2-networking-proxy-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/protection/replication":
      "dom-dt-2-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/remove-array":
      "dom-dt2-remove-array-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/settings/alerts-monitoring":
      "dom-dt-2-settings-alerts-monitoring-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/settings/date-time":
      "dom-dt-2-settings-date-time-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/settings/management-services":
      "dom-dt-2-settings-mgmt-services-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/settings/security":
      "dom-dt-2-settings-security-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/settings/system-settings":
      "dom-dt-2-system-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/:deviceID/storage-pools":
      "dom-dt2-storage-pools-tt-dscc",
    "/data-ops-manager/storage-systems/device-type2/volumes":
      "dom-volumes-tt-dscc",
    "/data-ops-manager/storage-systems/device-type3":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=CAPACITY":
      "dom-capacity-block-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=HARDWARE":
      "dom-dt4-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=ISSUES":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=MIGRATIONS":
      "dom-data-migration-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=NETWORKING":
      "dom-networking-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=PERFORMANCE":
      "dom-dt4-performance-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=PROTECTION":
      "dom-dt-4-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=SETTINGS":
      "dom-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=SOFTWARE":
      "dom-dt4-software-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=SUMMARY":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID?activeTab=SUSTAINABILITY":
      "dom-dt4-sustainability-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/enclosures/:enclosureId":
      "dom-dt4-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/networking/dns-servers":
      "dom-dt-4-networking-dns-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/networking/interfaces":
      "dom-dt-4-networking-interfaces-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/networking/proxy-settings":
      "dom-dt-4-networking-proxy-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/replication":
      "dom-dt-4-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/settings/alerts-monitoring":
      "dom-dt-4-settings-alerts-monitoring-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/settings/date-time":
      "dom-dt-4-settings-date-time-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/settings/license":
      "dom-dt-4-settings-license-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/settings/management-services":
      "dom-dt-4-settings-mgmt-services-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/settings/security":
      "dom-dt-4-settings-security-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/:deviceID/settings/system-settings":
      "dom-dt-4-system-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/volumes":
      "dom-volumes-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/volumes/:volumeUID":
      "dom-dt4-vm-analytics-volumes-tt-dscc",
    "/data-ops-manager/storage-systems/device-type4/volumes/:volumeUID/vms/:vmid":
      "dom-dt4-vm-analytics-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6":
      "dom-file-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=CAPACITY":
      "dom-capacity-file-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=HARDWARE":
      "dom-dt6-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=ISSUES":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=NETWORKING":
      "dom-networking-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=PERFORMANCE":
      "dom-dt6-performance-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=PROTECTION":
      "dom-protection-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=SETTINGS":
      "dom-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=SOFTWARE":
      "dom-dt6-software-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=USERS_GROUPS&activeSubTab=GROUPS":
      "dom-dt6-groups-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=USERS_GROUPS&activeSubTab=USERS":
      "dom-dt6-users-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=VIP_POOLS&activeSubTab=VIPPOOLS":
      "dom-dt6-vippools-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID?activeTab=VIP_POOLS&activeSubTab=VIPS":
      "dom-dt6-vips-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID":
      "dom-systems-block-devices-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/enclosures/:enclosureId":
      "dom-dt6-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/jbofs/:jbofId":
      "dom-dt6-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/networking/dns-servers":
      "dom-dt-6-networking-dns-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/networking/interfaces":
      "dom-dt-6-networking-interfaces-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/networking/proxy-settings":
      "dom-dt-6-networking-proxy-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/protection/replication":
      "dom-dt-6-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/replication-partner-candidates":
      "dom-dt-6-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/replication-partners":
      "dom-dt-6-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/replication-partners/:rpId":
      "dom-dt-6-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/settings/alerts-monitoring":
      "dom-dt-6-settings-alerts-monitoring-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/settings/authentication-providers?activeTab=AD":
      "dom-active-dir-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/settings/authentication-providers?activeTab=LDAP":
      "dom-ldap-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/settings/authentication-providers":
      "dom-authentication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/settings/date-time":
      "dom-dt-6-settings-date-time-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/settings/system-settings":
      "dom-dt-6-system-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/:deviceID/switches/:switchId":
      "dom-dt6-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type6/replication-partners":
      "dom-dt-6-protection-replication-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7": "dom-dashboard-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=CAPACITY":
      "dom-capacity-object-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=HARDWARE":
      "dom-dt7-hardware-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=ISSUES":
      "ms-issues-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=NETWORKING":
      "dom-networking-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=PERFORMANCE":
      "dom-dt7-performance-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=SETTINGS":
      "dom-settings-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID?activeTab=SOFTWARE":
      "dom-dt7-software-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID":
      "dom-dt7-system-details-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/networking/dns-servers":
      "dom-dt-7-networking-dns-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/networking/frontend-network-settings":
      "dom-dt-7-networking-frontend-network-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/networking/interfaces":
      "dom-dt-7-networking-interfaces-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/networking/proxy-settings":
      "dom-dt-7-networking-proxy-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/settings/alerts-monitoring":
      "dom-dt-7-settings-alerts-monitoring-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/settings/date-time":
      "dom-dt-7-settings-date-time-tt-dscc",
    "/data-ops-manager/storage-systems/device-type7/:deviceID/settings/system-settings":
      "dom-dt-7-system-settings-tt-dscc",
    "/data-ops-manager/volumes": "dom-volumes-tt-dscc",

    "/disaster-recovery": "gldr-general-tt-dscc",
    "/disaster-recovery/azure/vmas": "gldr-azure-vmas-tt-dscc",
    "/disaster-recovery/dashboard": "gldr-general-tt-dscc",
    "/disaster-recovery/encryption-detection/suspected-objects":
      "gldr-encryption-detection-tt-dscc",
    "/disaster-recovery/onprem-setup/vmas": "gldr-vma-tt-dscc",
    "/disaster-recovery/reports": "gldr-reports-tt-dscc",
    "/disaster-recovery/vcenter/vmas": "gldr-vma-tt-dscc",
    "/disaster-recovery/virtual-continuous-protection-groups":
      "gldr-protection-groups-tt-dscc",
    "/disaster-recovery/virtual-machines/on-prem-vms": "gldr-vms-tt-dscc",

    "/dual-authorization": "bar-dualauthorization-tt-dscc",
    "/dual-authorization/requests": "bar-dualauthorization-requests-tt-dscc",
    "/dual-authorization/settings": "bar-dualauthorization-settings-tt-dscc",

    "/email": "ps-email-tt-dscc",

    "/file-manager": "fs-overview-tt-dscc",
    "/file-manager/file-protection-policies/*": "fs-protection-tt-dscc",
    "/file-manager/file-share-settings/*": "fs-filesharesettings-tt-dscc",
    "/file-manager/file-shares": "fs-fileshares-tt-dscc",
    "/file-manager/file-shares/:id?tab=accounting":
      "fs-fileshares-by-id-accounting-dscc",
    "/file-manager/file-shares/:id?tab=capacity":
      "fs-fileshares-by-id-capacity-dscc",
    "/file-manager/file-shares/:id?tab=issue":
      "fs-fileshares-by-id-issues-dscc",
    "/file-manager/file-shares/:id?tab=performance":
      "fs-fileshares-by-id-performance-dscc",
    "/file-manager/file-shares/:id?tab=protection&subTab=protectedPath":
      "fs-fileshares-by-id-protection-path-dscc",
    "/file-manager/file-shares/:id?tab=protection&subTab=snapshots":
      "fs-fileshares-by-id-protection-snapshots-dscc",
    "/file-manager/file-shares/:id": "fs-fileshare-details-tt-dscc",
    "/file-manager/file-shares/create": "fs-fileshares-tt-dscc",
    "/file-manager/file-shares/edit/:id": "fs-fileshares-tt-dscc",
    "/file-manager/tasks": "ps-tasks-tt-dscc",
    "/file-manager/users-groups": "fs-usersandgroups-tt-dscc",

    "/issues": "ms-issues-tt-dscc",

    "/object-storage/access-policies": "objstr-access-policies-tt-dscc",
    "/object-storage/access-policies/new":
      "objstr-access-policies-create-tt-dscc",
    "/object-storage/buckets": "objstr-buckets-overview-tt-dscc",
    "/object-storage/buckets/:bucketID": "objstr-buckets-details-tt-dscc",
    "/object-storage/buckets/:bucketID/edit": "objstr-buckets-edit-tt-dscc",
    "/object-storage/buckets/new": "objstr-buckets-create-tt-dscc",
    "/object-storage/dashboard": "objstr-overview-tt-dscc",
    "/object-storage/groups": "objstr-groups-tt-dscc",
    "/object-storage/groups/new": "objstr-groups-create-tt-dscc",
    "/object-storage/systems/:systemID/access-policies/:accessID/edit":
      "objstr-access-policies-edit-tt-dscc",
    "/object-storage/systems/:systemID/groups/:groupID/edit":
      "objstr-groups-edit-tt-dscc",
    "/object-storage/systems/:systemID/users/:userID/edit":
      "objstr-users-edit-tt-dscc",
    "/object-storage/users": "objstr-users-tt-dscc",
    "/object-storage/users/new?multiple=true": "objstr-users-bulk-tt-dscc",
    "/object-storage/users/new": "objstr-users-create-tt-dscc",

    "/secrets": "ps-managesecrets-tt-dscc",

    "/setup": "sus-overview-tt-dscc",
    "/setup/devices": "sus-noblueprint-tt-dscc",
    "/setup/blueprints": "sus-blueprint-tt-dscc",

    "/private-cloud-ai/": "pcai-overview-tt-dscc",
    "/private-cloud-ai/connectors": "pcai-dsc-tt-dscc",
    "/private-cloud-ai/connectors/:id": "pcai-dsc-by-id-tt-dscc",
    "/private-cloud-ai/dashboard": "pcai-overview-tt-dscc",
    "/private-cloud-ai/systems": "pcai-systems-tt-dscc",
    "/private-cloud-ai/systems/:id": "pcai-systems-by-id-tt-dscc",
    "/private-cloud-ai/tasks": "ps-tasks-tt-dscc",

    "/private-cloud-business-edition": "pcbe-overview-tt-dscc",
    "/private-cloud-business-edition/cloud-accounts/*":
      "pcbe-cloudaccounts-tt-dscc",
    "/private-cloud-business-edition/connectors": "pcbe-dsc-tt-dscc",
    "/private-cloud-business-edition/connectors/:id": "pcbe-dsc-by-id-tt-dscc",
    "/private-cloud-business-edition/policies/*": "pcbe-vmprovpolicies-tt-dscc",
    "/private-cloud-business-edition/setup/systems/*": "pcbe-setup-tt-dscc",
    "/private-cloud-business-edition/systems": "pcbe-systems-tt-dscc",
    "/private-cloud-business-edition/systems/:id": "pcbe-systems-by-id-tt-dscc",
    "/private-cloud-business-edition/systems/:id/network":
      "pcbe-systemid-network-tt-dscc",
    "/private-cloud-business-edition/systems/:id/network/switches":
      "pcbe-systemid-network-switches-tt-dscc",
    "/private-cloud-business-edition/systems/:id/network/vlans":
      "pcbe-systemid-network-vlan-tt-dscc",
    "/private-cloud-business-edition/tasks": "ps-tasks-tt-dscc",
    "/private-cloud-business-edition/vcenter-servers":
      "pcbe-vcenter-servers-tt-dscc",
    "/private-cloud-business-edition/vcenter-servers/:id":
      "pcbe-vcenter-servers-by-id-tt-dscc",
    "/private-cloud-business-edition/virtual-machines/private-cloud":
      "pcbe-privatecloud-tt-dscc",
    "/private-cloud-business-edition/virtual-machines/private-cloud/:vmID":
      "pcbe-privatecloud-by-id-tt-dscc",
    "/private-cloud-business-edition/virtual-machines/public-cloud/*":
      "pcbe-publiccloud-tt-dscc",
    "/private-cloud-business-edition/vm-templates": "pcbe-vm-templates-tt-dscc",

    "/tasks": "ps-tasks-tt-dscc",
  },
}
