// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useNavigate } from "react-router-dom"
import { useEffect, useRef } from "react"
import authService from "./auth-service"
import { REDIRECT_URI_KEY } from "./constants"

export function AuthCallback() {
  const navigate = useNavigate()
  const runCallback = useRef(true)

  useEffect(() => {
    if (!runCallback.current) {
      return
    }
    runCallback.current = false

    authService
      .signinRedirectCallback()
      .then(() => {
        const redirectTo =
          window.sessionStorage.getItem(REDIRECT_URI_KEY) || "/"
        window.sessionStorage.removeItem(REDIRECT_URI_KEY)

        navigate(redirectTo, { replace: true })
      })
      .catch((e) => {
        console.error(e)
      })
  }, [navigate])

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  )
}
