// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { jwtDecode } from "jwt-decode"
import { I18N_STORAGE_KEY } from "../i18n-config/i18n"
import { env } from "../utils/env"
import { ACCOUNT_KEY } from "./constants"

// istanbul ignore next
const noop = () => {}

const mockAccessToken =
  env.REACT_APP_AUTH_TOKEN ||
  "eyJraWQiOiIud2VsbC1rbm93bi9vcGVuaWQtY29uZmlndXJhdGlvbiIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJ0ZXN0LnVzZXJAZXhhbXBsZS5jb20iLCJsYXN0TmFtZSI6IlVzZXIiLCJhdXRoX3NvdXJjZSI6ImxvY2FsIiwiZ2l2ZW5OYW1lIjoiVGVzdCIsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6MTgwMDkvLndlbGwta25vd24vb3BlbmlkLWNvbmZpZ3VyYXRpb24iLCJnaXZlbl9uYW1lIjoiVGVzdCIsImF1ZCI6IjBhYTJhYjgxLTNlMDktNDA4OC05ODk2LTYyYjc0ZmE0YTViNS10ZXN0IiwiaHBlX3dvcmtzcGFjZV9pZCI6InRlc3Qtd29ya3NwYWNlLWlkIiwidXNlcl9jdHgiOiJzYy10ZXN0LWN1c3RvbWVyLWlkIiwibmJmIjoxNzMwMjE3MjQ5LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZXhwIjoxNzMwMjE3ODQ5LCJpYXQiOjE3MzAyMTcyNDksImZhbWlseV9uYW1lIjoiVXNlciIsImp0aSI6IjVlMjJmY2VhLTFlMTItNDFjNS05N2MzLWNkMmVhMmNjNjhmZiIsImVtYWlsIjoidGVzdC51c2VyQGV4YW1wbGUuY29tIn0.kX4eE5iaapcx4psOvmIG93pix-pTYWjxw0NdBja0sLL2E2Hr4G29ndBSo2IeteIzOkIvNcpHcHuPKVMYhtcbLoYXEaD-QDZnd3WFQhMhgN9kN5-RH5XydOlpSF5iJeWrQ9umVepZsqQqIVnXjpik5KoGR-sW7Cn7UfUoU6VE5Bo4OL3uijfgXO2uAyWySik5yN7kdZLme9m8VKRWLvyE70Dq32EHnyRzRgmNVj5o3A0wd7V1VsvWO1c6_-Dz-4RvhTVUttpxYkbqub4oNrCOXSfM5JGlAhl_r7R3kZH9-l3axwJXU6ctWIcf5KllMkZdGx5xjU9rXbbUxg5bCkR5QA"

export default class MockAuthService {
  access_token
  mockUser
  oidcConfig
  storageKey

  constructor(settings) {
    if (!settings) {
      throw new Error("Settings required to construct MockAuthService")
    }
    this.oidcConfig = settings
    this.storageKey = `oidc.user:${settings.authority}:${settings.client_id}`

    const tokenDecoded = jwtDecode(mockAccessToken)
    this.access_token = tokenDecoded

    this.mockUser = {
      id_token: "some.id.token",
      access_token: mockAccessToken,
      expires_at: tokenDecoded.exp,
      profile: {
        acr: "urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified",
        auth_time: tokenDecoded.iat,
        email: tokenDecoded.sub,
        family_name: tokenDecoded.lastName,
        given_name: tokenDecoded.givenName,
        jti: "somejti",
        "pi.sri": "some..pi.sri",
        sid: "some..sid",
        sub: tokenDecoded.sub,
      },
      refresh_token: "somerefreshtoken",
      scope: "openid profile email",
      token_type: "Bearer",
    }
  }

  userManager = {
    clearStaleState: () => {
      // remove all local storage keys starting with "oidc."
      for (const key of Object.keys(window.localStorage)) {
        if (key.startsWith("oidc.")) {
          window.localStorage.removeItem(key)
        }
      }
      return Promise.resolve()
    },

    events: {
      addUserLoaded: noop,
      removeUserLoaded: noop,
    },

    getUser: () => Promise.resolve(this.mockUser),

    removeUser: () => {
      window.sessionStorage.removeItem(this.storageKey)
      return Promise.resolve()
    },

    signinRedirectCallback: () => {
      this.seedMockUser()
      return Promise.resolve(this.mockUser)
    },

    signoutRedirect: () =>
      new Promise((resolve) => {
        setTimeout(() => {
          window.location.assign(
            `${this.oidcConfig.authority}/idp/startSLO.ping`
          )
          resolve()
        }, 500)
      }),
  }

  getAccount = async () => {
    const user = await this.getUser()

    // decode JWT and get user_ctx claim
    return this.getAccountFromToken(user?.access_token)
  }

  getUser = () => this.userManager.getUser()

  getAccountFromToken = (token) => {
    let account
    try {
      const payload = jwtDecode(token)
      account = {
        applicationCustomerId: payload.user_ctx,
        workspaceId: payload.hpe_workspace_id,
      }
    } catch (err) {
      console.error(err)
    }
    return account
  }

  login = () => {
    window.location.replace("/auth")
  }

  logout = async () => {
    localStorage.removeItem(ACCOUNT_KEY)
    localStorage.removeItem(I18N_STORAGE_KEY)
    await this.userManager.removeUser()
    await this.userManager.clearStaleState()
    await this.userManager.signoutRedirect()
  }

  renewToken = noop

  // seed mock user in local/session storage
  seedMockUser = () => {
    // account detail in local storage
    window.localStorage.setItem(
      ACCOUNT_KEY,
      JSON.stringify({
        applicationCustomerId: this.access_token.user_ctx,
        workspaceId: this.access_token.hpe_workspace_id,
      })
    )
    // oidc user in session storage
    window.sessionStorage.setItem(
      this.storageKey,
      JSON.stringify(this.mockUser)
    )
    return this
  }

  // receive callback from idp after signinRedirect
  signinRedirectCallback = () => this.userManager.signinRedirectCallback()
}
