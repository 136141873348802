// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { AutoAnalyticsProvider } from "@glcp/greenlake-analytics"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useEffect } from "react"
import { useUser, useWorkspace } from "../hooks"
import { env } from "../utils/env"
import { buildTitle } from "../utils/title"

export function AnalyticsWrapper({ children }) {
  const user = useUser()
  const { workspace } = useWorkspace()
  const { "shell-enable-analytics-1": shellEnableAnalytics1 } = useFlags()

  const isAnalyticsEnabled =
    shellEnableAnalytics1 &&
    user?.profile.email &&
    workspace.platform_customer_id

  useEffect(() => {
    if (isAnalyticsEnabled) {
      // fire initial pageload event
      window.dispatchEvent(
        new CustomEvent("ce:pageview", {
          detail: {
            title: buildTitle(window.location.pathname),
            url: window.location.href,
          },
        })
      )
    }
  }, [isAnalyticsEnabled])

  return (
    <>
      {isAnalyticsEnabled ? (
        <AutoAnalyticsProvider
          analyticsConfig={{
            debugMessages: process.env.NODE_ENV === "development",
            allowConsentOverrides: process.env.NODE_ENV === "development",
            autoAnalytics: {
              analyticsBaseId: "analyticsBase",
              analyticsIds: ["data-test-id", "data-testid", "aria-label"],
              bodyAsAnalyticsBaseTag: true,
              idPatternsToTrack: [/.+/],
              urlsToTrack: [/.+/],
            },
            pageViewEnabled: false,
            plugins: {
              amplitude: {
                projectId: env.REACT_APP_AMPLITUDE_PROJECT_ID,
                apiKey: env.REACT_APP_AMPLITUDE_PROJECT_KEY,
              },
            },
          }}
          userProfileObject={{
            email: user?.profile.email,
          }}
          accountInfoObject={{
            account_status: workspace.account_status,
            account_type: workspace.account_type,
            platform_customer_id: workspace.platform_customer_id,
          }}
        >
          {children}
        </AutoAnalyticsProvider>
      ) : (
        children
      )}
    </>
  )
}
