// (c) Copyright 2025 Hewlett Packard Enterprise Development LP

import {
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  Heading,
  Paragraph,
  Text,
} from "grommet"
import { ShareRounded } from "grommet-icons"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useTranslation } from "react-i18next"
import cityscape from "../assets/cityscape.svg"
import { getTestIdAttribute, vcf } from "../utils/test-ids"

function VCFTile({ device, index }) {
  const { t } = useTranslation()
  return (
    <Card elevation="small" width="250px">
      <CardBody align="start" justify="between" gap="20px" pad="20px">
        <Text
          color="#444"
          size="24px"
          weight={500}
          {...getTestIdAttribute(`${vcf.tile.title}-${index}`)}
        >
          {device.name}
        </Text>
        <Text
          size="small"
          {...getTestIdAttribute(`${vcf.tile.description}-${index}`)}
        >
          {t("vcfDescription")}
        </Text>
        <Button
          href={device.link}
          target="_blank"
          rel="noopener noreferrer"
          {...getTestIdAttribute(`${vcf.tile.button}-${index}`)}
          label={
            <Box
              direction="row"
              gap="xsmall"
              align="center"
              pad={{ vertical: "1px" }}
            >
              <Text color="#444" size="19px" weight="bold">
                {t("launchpadActionButtonLabelLaunch")}
              </Text>
              <ShareRounded color="#444" />
            </Box>
          }
          secondary
          type="button"
        />
      </CardBody>
    </Card>
  )
}

export function VCF() {
  const { t } = useTranslation()
  const { "dscc-vmware-cloud-foundation": dsccVmwareCloudFoundation } =
    useFlags()
  const gridColumns =
    dsccVmwareCloudFoundation.length > 2
      ? ["auto", "auto", "auto"]
      : Array(dsccVmwareCloudFoundation.length).fill("auto")

  return (
    <Box
      fill
      overflow="auto"
      background={{
        color: "#f4f6f9",
        position: "center bottom",
        repeat: "no-repeat",
        size: "auto",
        image: `url(${cityscape})`,
      }}
      align="center"
    >
      <Box margin="xlarge" align="center" text-align="center" width="large">
        <Heading
          color="text-strong"
          size="large"
          margin={{ bottom: "large", top: "0px" }}
          weight={600}
          {...getTestIdAttribute(vcf.title)}
        >
          {t("welcome")}
        </Heading>
        <Paragraph
          fill
          size="small"
          color="text-strong"
          textAlign="center"
          margin={{ bottom: "large", top: "0px" }}
          {...getTestIdAttribute(vcf.paragraph)}
        >
          {t("vcfParagraph")}
        </Paragraph>
        <Grid
          columns={gridColumns}
          gap="small"
          {...getTestIdAttribute(vcf.grid)}
        >
          {dsccVmwareCloudFoundation.map((device, index) => (
            <VCFTile key={device.name} device={device} index={index} />
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
