// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect } from "react"
import authService from "./auth-service"
import { ACCOUNT_KEY } from "./constants"

export function StorageEventHandler() {
  useEffect(() => {
    const listener = async (e) => {
      // ignore storage events for other keys
      if (e.key !== ACCOUNT_KEY) {
        return
      }

      if (e.newValue == null) {
        // key was removed by another tab logging out, log out this tab
        return authService.logout()
      }

      let eventAccount
      try {
        eventAccount = JSON.parse(e.newValue)
      } catch (e) {
        // invalid JSON, do nothing
        console.error(e)
        return
      }
      const existingAccount = await authService.getAccount()
      if (
        existingAccount &&
        eventAccount.applicationCustomerId ===
          existingAccount.applicationCustomerId &&
        eventAccount.workspaceId === existingAccount.workspaceId
      ) {
        // same account as we currently have, do nothing
        return
      }

      // new account, get new tokens
      return authService.login({
        cid: eventAccount.applicationCustomerId,
        workspaceId: eventAccount.workspaceId,
      })
    }

    window.addEventListener("storage", listener)

    return () => window.removeEventListener("storage", listener)
  }, [])

  return null
}
