// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect } from "react"
import authService from "./auth-service"

/**
 * Single Logout endpoint
 */
export function Logout() {
  useEffect(() => {
    authService.logout()
  }, [])

  return null
}
