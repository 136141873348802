// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { assetPaths } from "../utils/env"

const { REACT_APP_REFERENCE_SRC: referenceSrc } = assetPaths

export function getAllowedPaths({
  paths = [],
  referenceAppPaths = allowedPaths["reference"] ?? [],
  src = "",
} = {}) {
  let allowedPaths = paths

  // add reference app routes if detected as placeholder app
  if (src === referenceSrc) {
    allowedPaths = Array.from(new Set(paths.concat(referenceAppPaths)))
  }

  // return in format [ { path: "/" }, ...] for later path matching
  return allowedPaths.map((path) => ({ path }))
}

/**
 * Paths notably do not have the `navPath` prefixes such as `/audit-log` or `/data-ops-manager`.
 * These will be attached to the route definitions themselves which contain this information.
 */
export const allowedPaths = {
  reference: ["/", "/table"],
  "allow-deny-manager": [
    "/",
    "/allow-entries",
    "/deny-entries",
    "/deny-reasons",
  ],
  announcements: ["/"],
  atlas: [
    "/",
    "/activities",
    "/analytics",
    "/analytics/activities",
    "/analytics/reports",
    "/analytics/reports/protection-jobs",
    "/dashboard",
    "/on-prem",
    "/on-prem/mssql",
    "/on-prem/mssql/databases",
    "/on-prem/mssql/databases/:id",
    "/on-prem/mssql/instances",
    "/on-prem/mssql/instances/:id",
    "/on-prem/mssql/protection-groups",
    "/on-prem/mssql/protection-groups/:id",
    "/on-prem/vmware",
    "/on-prem/vmware/datastores",
    "/on-prem/vmware/datastores/:id",
    "/on-prem/vmware/protection-groups",
    "/on-prem/vmware/protection-groups/:id",
    "/on-prem/vmware/vcenter-servers",
    "/on-prem/vmware/vcenter-servers/:id",
    "/on-prem/vmware/virtual-machines",
    "/on-prem/vmware/virtual-machines/:id",
    "/on-prem/volume",
    "/on-prem/volume/protection-groups",
    "/on-prem/volume/protection-groups/:id",
    "/onprem-setup",
    "/onprem-setup/data-orchestrators",
    "/onprem-setup/data-orchestrators/:id",
    "/onprem-setup/data-orchestrators/:id/network-interfaces",
    "/onprem-setup/protection-store-gateways",
    "/onprem-setup/protection-store-gateways/:id",
    "/onprem-setup/protection-store-gateways/:id/network-interfaces",
    "/onprem-setup/storeonces",
    "/onprem-setup/storeonces/:id",
    "/protection-policies",
    "/protection-policies/:id",
    "/protection-policies/template/:name",
    "/protection-stores",
    "/protection-stores/:id",
    "/protection-workloads",
    "/protection-workloads/application-hosts",
    "/protection-workloads/application-hosts/:id",
    "/protection-workloads/aws",
    "/protection-workloads/aws/accounts",
    "/protection-workloads/aws/accounts/:id",
    "/protection-workloads/aws/ebs",
    "/protection-workloads/aws/ebs/:id",
    "/protection-workloads/aws/ec2",
    "/protection-workloads/aws/ec2/:id",
    "/protection-workloads/aws/eks",
    "/protection-workloads/aws/eks/:id",
    "/protection-workloads/aws/eks/:id/:id",
    "/protection-workloads/aws/protection-groups",
    "/protection-workloads/aws/protection-groups/:id",
    "/protection-workloads/aws/rds",
    "/protection-workloads/aws/rds/:id",
    "/protection-workloads/azure",
    "/protection-workloads/azure/accounts",
    "/protection-workloads/azure/accounts/:id",
    "/protection-workloads/azure/disks",
    "/protection-workloads/azure/disks/:id",
    "/protection-workloads/azure/protection-groups",
    "/protection-workloads/azure/protection-groups/:id",
    "/protection-workloads/azure/tenants",
    "/protection-workloads/azure/tenants/:id",
    "/protection-workloads/azure/virtual-machines",
    "/protection-workloads/azure/virtual-machines/:id",
    "/protection-workloads/ms365",
    "/protection-workloads/ms365/organizations",
    "/protection-workloads/ms365/organizations/:id",
    "/protection-workloads/ms365/protection-groups",
    "/protection-workloads/ms365/protection-groups/:id",
    "/protection-workloads/ms365/users",
    "/protection-workloads/ms365/users/:id",
    "/protection-workloads/mssql",
    "/protection-workloads/mssql/databases",
    "/protection-workloads/mssql/databases/:id",
    "/protection-workloads/mssql/instances",
    "/protection-workloads/mssql/instances/:id",
    "/protection-workloads/mssql/protection-groups",
    "/protection-workloads/mssql/protection-groups/:id",
    "/protection-workloads/vmware",
    "/protection-workloads/vmware/datastores",
    "/protection-workloads/vmware/datastores/:id",
    "/protection-workloads/vmware/protection-groups",
    "/protection-workloads/vmware/protection-groups/:id",
    "/protection-workloads/vmware/vcenter-servers/",
    "/protection-workloads/vmware/vcenter-servers/:id",
    "/protection-workloads/vmware/virtual-machines",
    "/protection-workloads/vmware/virtual-machines/:id",
    "/protection-workloads/volume",
    "/protection-workloads/volume/protection-groups",
    "/protection-workloads/volume/protection-groups/:id",
    "/protection",
    "/protection/policies",
    "/protection/policies/:id",
    "/protection/policies/template/:name",
    "/protection/policies-v2",
    "/protection/policies-v2/:id",
    "/protection/policies-v2/template/:name",
    "/protection/stores",
    "/protection/stores/:id",
    "/reports",
    "/reports/protection-jobs",
    "/reports/protection-status",
    "/rop",
    "/setup/application-hosts",
    "/setup/application-hosts/:id",
    "/setup/data-orchestrators",
    "/setup/data-orchestrators/:id",
    "/setup/data-orchestrators/:id/network-interfaces",
    "/setup/protection-store-gateways",
    "/setup/protection-store-gateways/:id",
    "/setup/protection-store-gateways/:id/network-interfaces",
    "/setup/storeonces",
    "/setup/storeonces/:id",
    "/subscription",
    "/tasks",
  ],
  audit: ["/"],
  block: ["/"],
  "block-fleet": ["/", "/issues/:id", "/volumes"],
  "block-nimble": [
    "/:uid",
    "/:uid/snapshots/:snapshotId",
    "/volume-sets/:volumeSetUID",
  ],
  "block-primera": [
    "/applicationsets/:volumeSetUID",
    "/:volumeUID",
    "/:volumeUID/snapshots/:id",
    "/:volumeUID/vluns/:id",
    "/:volumeUID/vms/:vmid",
  ],
  "data-access": [
    "/host-initiators",
    "/host-initiators/:hostUid",
    "/host-initiator-groups",
    "/host-initiator-groups/:hgId",
  ],
  "data-observability": [
    "/",
    "/consumption",
    "/data-lineage",
    "/data-lineage/:id",
    "/inventory",
  ],
  "cluster-status": ["/"],
  "dual-auth": ["/", "/requests", "/settings"],
  "dom-apollo-object": [
    "/:systemUid",
    "/:systemUid/networking/dns-servers",
    "/:systemUid/networking/interfaces",
    "/:systemUid/networking/proxy-settings",
    "/:systemUid/nodes/:nodeId",
  ],
  "dom-fleet": [
    "/",
    "/dashboard",
    "/file-shares",
    "/file-shares/:shareUid",
    "/file-systems",
    "/issues/:id",
    "/storage-systems",
    "/storage-systems/:systemUid",
    "/storage-systems/buckets",
    "/storage-systems/volumes/:volumeUID",
    "/volumes",
  ],
  "dom-file": [
    "/",
    "/:systemUid",
    "/:systemUid/activedirectories",
    "/:systemUid/activedirectories/:adId",
    "/:systemUid/activedirectories/:adId/current-global-catalog",
    "/:systemUid/activedirectories/:adId/dcs",
    "/:systemUid/activedirectories/:adId/domains",
    "/:systemUid/activedirectories/:adId/global-catalogs",
    "/:systemUid/activedirectories/:adId/join-ad",
    "/:systemUid/activedirectories/:adId/leave-ad",
    "/:systemUid/audit-settings",
    "/:systemUid/enclosures",
    "/:systemUid/enclosures/:enclosureId",
    "/:systemUid/jbofs",
    "/:systemUid/jbofs/:jbofId",
    "/:systemUid/ldaps",
    "/:systemUid/ldaps/:ldapId",
    "/:systemUid/networking",
    "/:systemUid/networking/dns-servers",
    "/:systemUid/networking/interfaces",
    "/:systemUid/networking/proxy-settings",
    "/:systemUid/ports",
    "/:systemUid/ports/:portId",
    "/:systemUid/protection/replication",
    "/:systemUid/replication-partner-candidates",
    "/:systemUid/replication-partners",
    "/:systemUid/replication-partners/:rpId",
    "/:systemUid/settings",
    "/:systemUid/settings/alerts-monitoring",
    "/:systemUid/settings/audit-settings",
    "/:systemUid/settings/authentication-providers",
    "/:systemUid/settings/date-time",
    "/:systemUid/settings/system-settings",
    "/:systemUid/switches",
    "/:systemUid/switches/:switchId",
    "/replication-partners",
  ],
  "dom-homefleet": [
    "/:systemUid",
    "/:systemUid/enclosures",
    "/:systemUid/enclosures/:enclosureId",
    "/:systemUid/enclosures/:enclosureId/disks",
    "/:systemUid/enclosures/:enclosureId/disks/:diskId",
    "/:systemUid/hardware",
    "/:systemUid/jbofs",
    "/:systemUid/jbofs/:jbofId",
    "/:systemUid/networking",
    "/:systemUid/networking/dns-servers",
    "/:systemUid/networking/frontend-network-settings",
    "/:systemUid/networking/interfaces",
    "/:systemUid/networking/proxy-settings",
    "/:systemUid/network-settings",
    "/:systemUid/network-settings/:networkSettingId",
    "/:systemUid/nodes",
    "/:systemUid/nodes/:id",
    "/:systemUid/ports",
    "/:systemUid/ports/:portId",
    "/:systemUid/settings",
    "/:systemUid/settings/alerts-monitoring",
    "/:systemUid/settings/date-time",
    "/:systemUid/settings/security",
    "/:systemUid/settings/system-settings",
    "/:systemUid/switches",
    "/:systemUid/switches/:switchId",
  ],
  "dom-nimble": [
    "/:systemUid",
    "/:systemUid/add-array",
    "/:systemUid/controllers/:controllerId",
    "/:systemUid/disks/:diskId",
    "/:systemUid/hardware",
    "/:systemUid/network-settings/:networkSettingId",
    "/:systemUid/networking/dns-servers",
    "/:systemUid/networking/interfaces",
    "/:systemUid/networking/proxy-settings",
    "/:systemUid/ports/:portId",
    "/:systemUid/remove-array",
    "/:systemUid/settings/alerts-monitoring",
    "/:systemUid/settings/date-time",
    "/:systemUid/settings/management-services",
    "/:systemUid/settings/security",
    "/:systemUid/settings/system-settings",
    "/:systemUid/shelves/:shelfId",
    "/:systemUid/storage-pools",
    "/:systemUid/protection/replication",
    "/:systemUid/protection/templates",
    "/volumes/:uid",
    "/volumes/:uid/snapshots/:snapshotId",
    "/volume-sets/:volumeSetUID",
  ],
  "dom-objectStorage": [
    "/access-policies",
    "/buckets",
    "/buckets/:id",
    "/groups",
    "/users",
  ],
  "dom-primera": [
    "/:systemUid",
    "/:systemUid/enclosures",
    "/:systemUid/enclosures/:id",
    "/:systemUid/enclosures/:id/disks/:diskId",
    "/:systemUid/enclosures/:id/enclosure-card-ports/:id",
    "/:systemUid/enclosures/:id/enclosure-cards/:id",
    "/:systemUid/enclosures/:id/enclosure-disks/:id",
    "/:systemUid/enclosures/:id/enclosure-expanders/:id",
    "/:systemUid/enclosures/:id/enclosure-fans/:id",
    "/:systemUid/enclosures/:id/enclosure-powers/:id",
    "/:systemUid/enclosures/:id/enclosure-sleds/:id",
    "/:systemUid/cpg",
    "/:systemUid/networking/cdc-servers",
    "/:systemUid/networking/dns-servers",
    "/:systemUid/networking/interfaces",
    "/:systemUid/networking/proxy-settings",
    "/:systemUid/nodes",
    "/:systemUid/nodes/:id",
    "/:systemUid/nodes/:id/node-cards/:id",
    "/:systemUid/nodes/:id/node-cpus/:id",
    "/:systemUid/nodes/:id/node-drives/:id",
    "/:systemUid/nodes/:id/node-mcus/:id",
    "/:systemUid/nodes/:id/node-mems/:id",
    "/:systemUid/nodes/:id/node-powers/:id",
    "/:systemUid/nodes/:id/nodes-batteries/:id",
    "/:systemUid/ports",
    "/:systemUid/ports/:id",
    "/:systemUid/settings/alerts-monitoring",
    "/:systemUid/settings/date-time",
    "/:systemUid/settings/license",
    "/:systemUid/settings/management-services",
    "/:systemUid/settings/security",
    "/:systemUid/settings/system-settings",
    "/:systemUid/switches",
    "/:systemUid/switches/:id",
    "/:systemUid/replication",
    "/volumes/:volumeUID",
    "/applicationsets/:volumeSetUID",
    "/volumes/:volumeUID/snapshots/:id",
    "/volumes/:volumeUID/vluns/:id",
    "/volumes/:volumeUID/vms/:vmid",
  ],
  "dom-sds": ["/", "/aws-accounts", "/sds-systems"],
  email: ["/"],
  "file-manager": [
    "/",
    "/capacity-quotas",
    "/dashboard",
    "/file-protection-policies",
    "/file-protection-policies/:id",
    "/file-server/:id",
    "/file-servers",
    "/file-servers/create",
    "/file-share-settings",
    "/file-share-settings/:id",
    "/file-share-settings/create",
    "/file-share-settings/edit/:id",
    "/file-shares",
    "/file-shares/:id",
    "/file-shares/:id/edit",
    "/file-shares/:id/metrics-history",
    "/file-shares/:id/protection-policies/:prid",
    "/file-shares/:id/protection-policies/:prid/abort-failover",
    "/file-shares/:id/protection-policies/:prid/add-stream",
    "/file-shares/:id/protection-policies/:prid/commit-restore",
    "/file-shares/:id/protection-policies/:prid/failover",
    "/file-shares/:id/protection-policies/:prid/pause-restore",
    "/file-shares/:id/protection-policies/:prid/reattach-stream",
    "/file-shares/:id/protection-policies/:prid/remove-stream",
    "/file-shares/:id/protection-policies/:prid/resume-restore",
    "/file-shares/:id/protection-policies/:prid/stop-restore",
    "/file-shares/:id/snapshots",
    "/file-shares/:id/snapshots/:snapid",
    "/file-shares/:id/update-protection-policies",
    "/file-shares/:id/update-quota-settings",
    "/file-shares/create",
    "/file-shares/edit/:id",
    "/file-system/:id",
    "/file-system/edit/:id",
    "/file-systems",
    "/file-systems/:id",
    "/file-systems/create",
    "/file-tenants",
    "/file-tenants/:id",
    "/tasks",
    "/users-groups",
  ],
  groups: ["/", "/:id/associations"],
  issues: ["/"],
  kubernetesService: ["/", "/clusters", "/clusters/:id", "/dashboard"],
  links: ["/"],
  objectStorage: [
    "/",
    "/access-policies",
    "/access-policies/:id",
    "/access-policies/new",
    "/bucket-templates",
    "/bucket-templates/:id",
    "/bucket-templates/:id/edit",
    "/bucket-templates/new",
    "/buckets",
    "/buckets/:id",
    "/buckets/:id/edit",
    "/buckets/new",
    "/dashboard",
    "/groups",
    "/groups/:id",
    "/groups/new",
    "/issues",
    "/issues/:id",
    "/systems/:systemId/access-policies/:id/edit",
    "/systems/:systemId/groups/:id/edit",
    "/systems/:systemId/users/:id/edit",
    "/users",
    "/users/:id",
    "/users/new",
  ],
  pcai: [
    "/",
    "/connectors",
    "/connectors/:id",
    "/dashboard",
    "/systems",
    "/systems/:id",
    "/tasks",
  ],
  pcbe: [
    "/",
    "/cloud-accounts",
    "/cloud-accounts/:id",
    "/connectors",
    "/connectors/:id",
    "/dashboard",
    "/policies",
    "/policies/:id",
    "/setup/systems",
    "/systems",
    "/systems/:id",
    "/systems/:id/clusters",
    "/systems/:id/clusters/:clusterId",
    "/systems/:id/network",
    "/systems/:id/network/:id",
    "/systems/:id/network/switches",
    "/systems/:id/network/vlans",
    "/tasks",
    "/vcenter-servers",
    "/vcenter-servers/:id",
    "/virtual-machines/private-cloud",
    "/virtual-machines/private-cloud/:id",
    "/virtual-machines/public-cloud",
    "/virtual-machines/public-cloud/:id",
    "/vm-templates",
  ],
  search: ["/"],
  secrets: ["/", "/:id"],
  setup: [
    "/",
    "/devices",
    "/blueprints",
    "/configure",
    "/configure/attributes",
    "/configure/attributes/proxy",
    "/configure/attributes/infosight",
    "/configure/attributes/contact",
    "/configure/dns",
    "/configure/hci/cluster",
    "/configure/hci/network",
    "/configure/hci/passwords",
    "/configure/hci/server",
    "/configure/hci/summary",
    "/configure/hci/vcenter",
    "/configure/network/management",
    "/configure/network/review",
    "/configure/network/subnet",
    "/configure/private-cloud-system/cluster",
    "/configure/private-cloud-system/network",
    "/configure/private-cloud-system/passwords",
    "/configure/private-cloud-system/server",
    "/configure/private-cloud-system/summary",
    "/configure/private-cloud-system/vcenter",
    "/configure/review",
    "/configure/system",
    "/configure/time",
    "/configure/welcome",
    "/configure/s3DataNetwork",
  ],
  sfm: [
    "/",
    "/dashboard",
    "/datacenters",
    "/datacenters/:datacenterid",
    "/devices",
    "/devices/managed-devices",
    "/devices/managed-devices/:deviceid",
    "/devices/managed-devices/:deviceid/eth-switches",
    "/devices/managed-devices/:deviceid/eth-switches/:switchid",
    "/devices/managed-devices/:deviceid/eth-switches/:switchid/lags",
    "/devices/managed-devices/:deviceid/eth-switches/:switchid/lags/:lagid",
    "/devices/managed-devices/:deviceid/eth-switches/:switchid/vlans",
    "/devices/managed-devices/:deviceid/eth-switches/:switchid/vlans/:vlanid",
    "/devices/managed-devices/:deviceid/fabrics",
    "/devices/managed-devices/:deviceid/fabrics/:fabricid",
    "/devices/managed-devices/:deviceid/fabrics/:fabricid/name-server",
    "/devices/managed-devices/:deviceid/fabrics/:fabricid/zone-aliases",
    "/devices/managed-devices/:deviceid/fabrics/:fabricid/zone-configurations",
    "/devices/managed-devices/:deviceid/fabrics/:fabricid/zones",
    "/devices/managed-devices/:deviceid/fibre-channel-switches",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/environmental",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/general",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/high-availability",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/interfaces",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/configuration",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/general",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/sfp",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/statistics",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/licenses",
    "/devices/managed-devices/:deviceid/fibre-channel-switches/:switchid/logical-switches",
    "/devices/spock-report",
    "/devices/storage-arrays",
    "/devices/storage-fabrics",
    "/devices/storage-fabrics/:deviceid",
    "/devices/storage-fabrics/:deviceid/device-aliases",
    "/devices/storage-fabrics/:deviceid/eth-switches",
    "/devices/storage-fabrics/:deviceid/eth-switches/:switchid",
    "/devices/storage-fabrics/:deviceid/eth-switches/:switchid/lags",
    "/devices/storage-fabrics/:deviceid/eth-switches/:switchid/lags/:lagid",
    "/devices/storage-fabrics/:deviceid/eth-switches/:switchid/vlans",
    "/devices/storage-fabrics/:deviceid/eth-switches/:switchid/vlans/:vlanid",
    "/devices/storage-fabrics/:deviceid/fabrics",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid/fc-aliases",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid/name-server",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid/zone-aliases",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid/zone-configurations",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid/zone-sets",
    "/devices/storage-fabrics/:deviceid/fabrics/:fabricid/zones",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/environmental",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/general",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/high-availability",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/interfaces",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/configuration",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/general",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/sfp",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/interfaces/:interfaceid/statistics",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/licenses",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/logical-switches",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/terminal",
    "/devices/storage-fabrics/:deviceid/fibre-channel-switches/:switchid/vsans",
    "/devices/storage-fabrics/:deviceid/links",
    "/devices/storage",
    "/devices/topology",
    "/fabric-proxies",
    "/fabric-proxies/:fabricproxyid",
    "/syslog",
    "/templates",
    "/templates/:templateid",
    "/templates/:templateid/edit",
  ],
  shapeshifter: [
    "/",
    "/volumes",
    "/volumes/migrate",
    "/volumes/:id",
    "/file-systems",
    "/file-systems/migrate",
    "/file-systems/:id",
  ],
  tasks: ["/"],
  webhooks: ["/"],
  zerto: [
    "/",
    "/azure",
    "/azure/vmas",
    "/dashboard",
    "/encryption-detection",
    "/encryption-detection/monitoring",
    "/encryption-detection/settings",
    "/encryption-detection/suspected-objects",
    "/onprem-setup/hosts",
    "/onprem-setup/vmas",
    "/reports",
    "/vcenter/hosts",
    "/vcenter/vmas",
    "/virtual-continuous-protection-groups",
    "/virtual-machines",
    "/virtual-machines/cloud-vms",
    "/virtual-machines/cloud-vms/:vmId/:vpgId/issues",
    "/virtual-machines/cloud-vms/:vmId/:vpgId/tasks",
    "/virtual-machines/on-prem-vms",
    "/virtual-machines/on-prem-vms/:vmId/:vpgId/issues",
    "/virtual-machines/on-prem-vms/:vmId/:vpgId/tasks",
  ],
}
