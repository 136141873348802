// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { initialize } from "launchdarkly-js-client-sdk"
import { isONPREM } from "./utils/env"
import { LDConfig, OfflineClient } from "./utils/launchdarkly"

export class FlagManager {
  constructor() {
    this.client = null
  }

  getFlag = (key, defaultValue) => {
    return this.client?.variation(key, defaultValue) ?? defaultValue
  }

  getLDConfig = () => {
    return {
      clientSideID: LDConfig.clientSideID,
      ldClient: this.client,
      options: LDConfig.options,
      context: this.client?.getContext() ?? {},
      timeout: 3, // LD client initialization timeout in seconds
    }
  }

  init = async (context = { kind: "user", anonymous: true }) => {
    this.client = isONPREM()
      ? OfflineClient
      : initialize(LDConfig.clientSideID, context, LDConfig.options)

    await this.client.waitUntilReady()

    return this
  }
}

export const flagManager = new FlagManager()
