// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { jwtDecode } from "jwt-decode"
import { Log, UserManager } from "oidc-client"
import { ACCOUNT_KEY, CID_KEY, MOCK_AUTH, REDIRECT_URI_KEY } from "./constants"
import { dispatchTokenRefreshEvent } from "../events"
import { I18N_STORAGE_KEY } from "../i18n-config/i18n"
import MockAuthService from "./mock-auth-service"
import OidcConfig from "./oidc-config"

export class AuthService {
  userManager

  constructor(settings) {
    if (!settings) {
      throw new Error("Settings required to construct AuthService")
    }
    this.userManager = new UserManager(settings)

    this.userManager.events.addAccessTokenExpiring(() => {
      // use refresh token to try renewing access token
      this.renewToken().catch(() => {})
    })

    this.userManager.events.addAccessTokenExpired(() => {
      // refresh token has a longer lifetime and may still be valid
      // try to renew access with refresh token
      this.renewToken().catch(() => {
        // either no refresh token available or bad response from token endpoint
        // clear user and redirect to login page to start login flow
        this.logoutLocal()
      })
    })

    // istanbul ignore next
    if (process.env.NODE_ENV === "development") {
      Log.logger = console
      Log.level = Log.DEBUG
    }
  }

  getAccount = async () => {
    const user = await this.getUser()

    // decode JWT and get user_ctx claim
    return this.getAccountFromToken(user?.access_token)
  }

  getUser = () => {
    return this.userManager.getUser()
  }

  getAccountFromToken = (token) => {
    let account
    try {
      const payload = jwtDecode(token)
      account = {
        applicationCustomerId: payload.user_ctx,
        workspaceId: payload.hpe_workspace_id,
      }
    } catch (err) {
      console.error(err)
    }
    return account
  }

  login = ({ cid, workspaceId }) => {
    // store cid in localStorage to broadcast account change event
    // to any DSCC instances open in other tabs
    localStorage.setItem(
      ACCOUNT_KEY,
      JSON.stringify({ applicationCustomerId: cid, workspaceId })
    )

    return this.userManager.signinRedirect({
      extraQueryParams: { cid, workspace_id: workspaceId },
      useReplaceToNavigate: true,
    })
  }

  // receive callback from idp after signinRedirect
  signinRedirectCallback = () => this.userManager.signinRedirectCallback()

  renewToken = async () => {
    const user = await this.userManager.getUser()

    if (user && user.refresh_token) {
      await this.userManager._useRefreshToken({
        refresh_token: user.refresh_token,
      })
      dispatchTokenRefreshEvent()
    } else {
      throw new Error("No refresh token present, unable to renew tokens")
    }
  }

  logout = async () => {
    localStorage.removeItem(ACCOUNT_KEY)
    localStorage.removeItem(CID_KEY)
    localStorage.removeItem(I18N_STORAGE_KEY)
    await this.userManager.removeUser()
    await this.userManager.clearStaleState()
    await this.userManager.signoutRedirect({
      extraQueryParams: {
        InErrorResource: OidcConfig.post_logout_redirect_uri,
        TargetResource: OidcConfig.post_logout_redirect_uri,
      },
    })
  }

  logoutLocal = async () => {
    this.userManager.removeUser()
    this.userManager.clearStaleState()
    // save current page in sessionStorage so it can be restored upon successful login
    window.sessionStorage.setItem(
      REDIRECT_URI_KEY,
      window.location.href.replace(window.location.origin, "")
    )
    // avoid full page refresh by pushing destination path
    // then dispatching a popstate event to trigger the router to rerender
    window.history.pushState(null, "", "/login")
    window.dispatchEvent(new PopStateEvent("popstate"))
  }
}

const authService = MOCK_AUTH
  ? new MockAuthService(OidcConfig).seedMockUser()
  : new AuthService(OidcConfig)

export default authService
