// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { matchRoutes } from "react-router-dom"
import { FLAG_ANALYTICS, initialize, onUrlChange } from "./analytics"
import { dispatchNavigationEvent, NavigationEventType } from "./events"
import rtmClient from "./rtm-client"
import { createShellAPI } from "./shell-api"
import { routes } from "./shell-routes"
import { FLAG_RTM } from "./utils/constants"
import { getRoute } from "./utils/location"
import { buildTitle } from "./utils/title"

export function isPathAllowed(path, route) {
  const matched = matchRoutes(route.allowedPaths, path)
  return !!matched
}

export function redirectToLaunchpadWithFlash(deniedPath, targetWindow) {
  targetWindow.dispatchEvent(
    new CustomEvent(NavigationEventType.DENIED, {
      detail: { deniedPath },
    })
  )
  targetWindow.history.replaceState(null, "", "/")
  targetWindow.dispatchEvent(new PopStateEvent("popstate"))
}

export function normalizePathname(path, route) {
  // `path` could be a string URL or URL object
  // Before we get the appropriate pathname, need to check if `path` is
  // an object(and not null/undefined) and that `pathname` exists
  const pathname =
    path instanceof URL ? path.href.replace(path.origin, "") : path

  if (!pathname || pathname === route.basePath || pathname === route.navPath) {
    return "/"
  }

  if (pathname.startsWith(route.basePath)) {
    return pathname.slice(route.basePath.length)
  }

  return pathname.startsWith(route.navPath)
    ? pathname.slice(route.navPath.length)
    : pathname
}

export function decorateHistoryMethods(window, callback) {
  let origPushState = window.history.pushState
  window.history.pushState = (state, title, url) => {
    origPushState.apply(window.history, [state, title, url])
    if (typeof window.history.onpushstate === "function") {
      window.history.onpushstate({
        type: "pushstate",
        state,
        title,
        path: url,
      })
    }
  }

  let origReplaceState = window.history.replaceState
  window.history.replaceState = (state, title, url) => {
    origReplaceState.apply(window.history, [state, title, url])
    if (typeof window.history.onreplacestate === "function") {
      window.history.onreplacestate({
        type: "replacestate",
        state,
        title,
        path: url,
      })
    }
  }

  window.onpopstate =
    window.history.onpushstate =
    window.history.onreplacestate =
      callback
}

export function createShellHistoryCallback(window) {
  dispatchNavigationEvent({ url: window.location.href, type: "pageload" })

  return (e) => {
    const newPath = ["pushstate", "replacestate"].includes(e.type)
      ? e.path
      : window.location.href.replace(window.location.origin, "")
    dispatchNavigationEvent({
      url: newPath
        ? new URL(newPath, window.location.href).href
        : window.location.href,
      type: e.type,
    })

    // third arg to pushState|replaceState is optional in which case it stays on the same page
    // https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
    // if newPath is null | undefined | '' then no need to update microapp URL
    if (!newPath) return

    const route = getRoute(newPath)

    if (route.type === "frame") {
      const microAppPath =
        newPath === route.navPath ? "/" : newPath.replace(route.navPath, "")

      if (["pushstate", "popstate"].includes(e.type)) {
        // this doesn't exist if we haven't visited the base route yet
        const targetIframe = document.querySelector(
          `iframe[name="micro-app-${route.key}"]`
        )
        if (targetIframe) {
          const targetWindow = targetIframe.contentWindow
          const currentPath = normalizePathname(
            targetWindow.location.href.replace(
              targetWindow.location.origin,
              ""
            ),
            routes[route.key]
          )

          if (microAppPath !== currentPath) {
            targetWindow.history.replaceState(
              null,
              "",
              `${route.basePath}${microAppPath}`
            )
            targetWindow.dispatchEvent(new PopStateEvent("popstate"))
          }
        }
      }
    }
  }
}

/**
 * Listens for shell navigation events to trigger document title updates.
 *
 * The listener queues up a task at end of event loop, ensuring window/document/history have settled.
 * Without zero-delay setTimeout the title will be updated before the entry is pushed onto
 * the history stack, resulting in current page title replacing previous in browser history.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event#the_history_stack
 */
function manageDocTitleUpdates(target) {
  target.addEventListener(NavigationEventType.SHELL, (e) => {
    setTimeout(() => {
      target.document.title = buildTitle(e.detail.pathname)
    })
  })
}

export function initShell({ flagClient, window }) {
  if (flagClient.getFlag(FLAG_ANALYTICS, false)) {
    initialize()
    const onShellNav = onUrlChange((e) => {
      window.dispatchEvent(
        new CustomEvent("ce:pageview", {
          detail: {
            title: buildTitle(e.detail.pathname),
            url: e.detail.href,
          },
        })
      )
    })
    window.addEventListener(NavigationEventType.SHELL, onShellNav)
  }

  if (flagClient.getFlag(FLAG_RTM, false)) {
    rtmClient.setup()
  }

  window.ShellAPI = createShellAPI()

  manageDocTitleUpdates(window)

  decorateHistoryMethods(window, createShellHistoryCallback(window))
}

export function initMicroApp({ iframe, route } = {}) {
  const targetWindow = iframe.contentWindow
  let lastPath = normalizePathname(
    targetWindow.location.href.replace(targetWindow.location.origin, ""),
    route
  )
  dispatchNavigationEvent({
    eventType: NavigationEventType.MICROAPP,
    url: targetWindow.location.href,
    type: "pageload",
  })

  if (!isPathAllowed(lastPath, route)) {
    return redirectToLaunchpadWithFlash(
      `${route.navPath}/${lastPath}`,
      targetWindow
    )
  }
  decorateHistoryMethods(targetWindow, historyTrackingCallback)

  const onMouseOrTouch = (event) => {
    const proxyEvent = new CustomEvent("ce:click", {
      bubbles: true,
      cancelable: false,
    })
    proxyEvent.originalEvent = event

    iframe.dispatchEvent(proxyEvent)
  }
  targetWindow.addEventListener("mousedown", onMouseOrTouch)
  targetWindow.addEventListener("touchend", onMouseOrTouch)

  function historyTrackingCallback(e) {
    const eventPath = ["pushstate", "replacestate"].includes(e.type)
      ? e.path
      : targetWindow.location.href.replace(targetWindow.location.origin, "")

    const newPath = eventPath ? normalizePathname(eventPath, route) : null

    dispatchNavigationEvent({
      eventType: NavigationEventType.MICROAPP,
      url: newPath
        ? new URL(newPath, targetWindow.location.href).href
        : targetWindow.location.href,
      type: e.type,
    })

    // third arg to pushState|replaceState is optional in which case it stays on the same page
    // https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
    // if eventPath is null | undefined | '' then no need to update shell URL
    if (!newPath) {
      return
    }

    if (!isPathAllowed(newPath, route)) {
      return redirectToLaunchpadWithFlash(
        `${route.navPath}/${newPath}`,
        targetWindow
      )
    }

    if (e.type === "popstate" && e.singleSpa) {
      lastPath = newPath
      return
    }

    targetWindow.top.history.replaceState(
      null,
      "",
      `${route.navPath}${newPath === "/" ? "" : newPath}`
    )

    lastPath = newPath
  }
}
