// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { Box } from "grommet"
import { useLocation, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MicroAppLoading from "./micro-app-loading"
import {
  initMicroApp,
  isPathAllowed,
  redirectToLaunchpadWithFlash,
} from "../init"
import { getTestIdAttribute } from "../utils/test-ids"

const iframeStyle = {
  // remove iframe default border
  border: "none",

  // fix for safari <= 14.1.11 iframe
  // not respecting style { height: 100%; }
  height: 0,
  minHeight: "100%",
  width: 0,
  minWidth: "100%",
}

export default function MicroApp({ route }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const { "*": rest } = useParams()
  const microAppRef = useRef()

  const handleLoad = useCallback(() => {
    initMicroApp({
      iframe: microAppRef.current,
      route,
    })

    setIsLoading(false)
  }, [route])

  useLayoutEffect(() => {
    // validate allowed path prior to rendering iframe
    // this will redirect on not allowed paths even if the iframe does not load,
    // i.e. cypress tests, cross-origin access, error fetching assets, etc.
    if (!isPathAllowed(`/${rest}`, route)) {
      redirectToLaunchpadWithFlash(`${route.navPath}/${rest}`, window)
    }
  }, [rest, route])

  useLayoutEffect(() => {
    if (microAppRef.current) {
      // hide microapp until loaded
      microAppRef.current.style.display = isLoading ? "none" : "inherit"
    }
  }, [isLoading])

  return (
    <>
      {isLoading && <MicroAppLoading name={t(route.title)} />}
      <Box
        key={route.key}
        as="iframe"
        name={`micro-app-${route.key}`}
        onLoad={handleLoad}
        ref={microAppRef}
        src={`${route.src}/${rest}${location.search}${location.hash}`}
        style={iframeStyle}
        title={t(route.title)}
        {...getTestIdAttribute(`${route.key}-iframe`)}
      />
    </>
  )
}
